<template>
  <div class="container">
    
    <div class="calendar mb-3">
      <div class="row mt-5">
        <div class="col-3">
          <a :href="'/moon/' + backmonth + '/' + backyear+ '/' + $route.params.id"
            v-if="$route.params.month != 6 || $route.params.year != 2024" ><i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i
          ></a>
        </div>
        <div class="col-6" style="text-align: center">
          <h4>{{ monthname }} {{ year }}</h4>
        </div>
        <div class="col-3" style="text-align: right">
          <a :href="'/moon/' + nextmonth + '/' + nextyear+ '/' + $route.params.id"
            ><i class="fa fa-arrow-right fa-2x" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div class="row mt-3">
        <div class="dayname" v-for="w in weekday" :key="w.id" :style="'background-color:' + w.class">{{ w.name }}</div>
      </div>
      <!-- {{list}} -->

      <div class="row" v-for="(l, i) in list" :key="i + 1">
        <div
          class="day"
          :style="'background-color:' + s.class"
          v-for="(s, si) in l.sub"
          :key="si + 1"
          @click="add(s)"
        >
          <div class="day-number">{{ s.date }}
            <img style="text-align:center" :src="s.image" width="100%">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <TypeOne/>
      <!-- <div class="col-3"></div> -->
      <!-- <div class="col-6"><TypeOne/></div> -->
      <!-- <div class="col-3"></div> -->
    </div>
    <!-- Button trigger modal -->
    <button
      type="button"
      style="display: none"
      id="openmodel"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#exampleModal"
    >
      Launch demo modal
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ datename }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-6 mt-3">
              <div class="form-check mt-3" v-for="(m, mi) in moods" :key="mi + 1">
                <label class="form-check-label" :for="m.id">
                <input
                  class="form-check-input"
                  type="radio"
                  name="type"
                  :id="m.id"
                  v-model="data.moodId"
                  :value="m.id"
                />
                  <img :src="m.image" style="width: 120px;">
                  <!-- <div
                    class="square"
                    :style="'background-color:' + m.class"
                  ></div> -->
                  <!-- {{ m.name }} -->
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" @click="save()">
              Save
            </button>
            <button type="button" class="btn btn-danger" @click="deletedata()">
              Delete
            </button>
            <button
              id="closemodal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeOne from '../components/TypeOne.vue'

import UrlService from "../services/UrlService";
import CountService from "../services/CountService";
import MoodTrackingService from "../services/MoodTrackingService";
import MoodService from "../services/MoodService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
    TypeOne,
  },
  data() {
    return {
      list: [],
      weekday: [
        {
          id: 0,
          name: "Sun",
          class:'#EA081E'
        },
        {
          id: 1,
          name: "Mon",
          class:'#FEF13C'
        },
        {
          id: 2,
          name: "Tue",
          class:'#FF008A'
        },
        {
          id: 3,
          name: "Wed",
          class:'#5EA51C'
        },
        {
          id: 4,
          name: "Thu",
          class:'#FF8601'
        },
        {
          id: 5,
          name: "Fri",
          class:'#00F0DB'
        },
        {
          id: 6,
          name: "Sat",
          class:'#8587EA'
        },
      ],
      year: "",
      month: "",
      monthname: "",
      backmonth: "",
      backyear: "",
      nextmonth: "",
      nextyear: "",
      listdata: "",
      datename: "",
      data: {},
      moods: [],
    };
  },
  mounted() {
    console.log(1);
    // console.log(this.$route.params.month);
    // this.getURL();
    const d = new Date(
      this.$route.params.year,
      this.$route.params.month - 1,
      1
    );
    // let day = d.getDay()
    this.backmonth = this.$route.params.month - 1;
    this.backyear = this.$route.params.year;
    if (this.$route.params.month == 1) {
      this.backyear = this.$route.params.year - 1;
      this.backmonth = 12;
    }
    this.nextmonth = parseInt(this.$route.params.month) + 1;
    this.nextyear = this.$route.params.year;
    if (this.$route.params.month == 12) {
      this.nextyear = parseInt(this.$route.params.year) + 1;
      this.nextmonth = 1;
    }
    this.month = this.$route.params.month;
    this.monthname = d.toLocaleString("default", { month: "long" });
    this.year = this.$route.params.year;
    // console.log(this.month);

    MoodTrackingService.getmood_trackings(this.month, this.year, 1).then(
      (res) => {
        // console.log(res.data);
        this.listdata = res.data;
        if (this.listdata.length == 0) {
          this.insertdata();
        } else {
          this.getdata();
        }
        this.getmoods();
      }
    );

    // console.log(this.list);
  },
  methods: {
    deletedata() {
      var update = {
        moodId: 0,
      };
      MoodTrackingService.updatemood_tracking(this.data.id, update).then(() => {
        // console.log(res.data);
        document.getElementById("closemodal").click();
        this.getdata();
        location.reload();
      });
    },
    save() {
      var update = {
        moodId: this.data.moodId,
      };
      MoodTrackingService.updatemood_tracking(this.data.id, update).then(() => {
        // console.log(res.data);
        document.getElementById("closemodal").click();
        this.getdata();
        location.reload();
      });
    },
    getmoods() {
      MoodService.getmoods().then((res) => {
        this.moods = res.data;
      });
    },
    isInt(n) {
      return Number(n) === n && n % 1 === 0;
    },
    getdata() {
      this.list = [];
      MoodTrackingService.getmood_trackings(this.month, this.year, 1).then(
        (res) => {
          // console.log(res.data);
          this.listdata = res.data;
          var splitweek = this.listdata.length / 7;

          for (let l = 0; l < splitweek; l++) {
            this.list.push({ id: l + 1, sub: [] });
          }

          // console.log(this.list,'list');
          var one = [];
          var two = [];
          var three = [];
          var four = [];
          var five = [];
          var six = [];

          for (var i = 0; i < this.listdata.length; i++) {
            if (this.listdata[i].no <= 7) {
              one.push(this.listdata[i]);
            } else if (this.listdata[i].no > 7 && this.listdata[i].no <= 14) {
              two.push(this.listdata[i]);
            } else if (this.listdata[i].no > 14 && this.listdata[i].no <= 21) {
              three.push(this.listdata[i]);
            } else if (this.listdata[i].no > 21 && this.listdata[i].no <= 28) {
              four.push(this.listdata[i]);
            } else if (this.listdata[i].no > 28 && this.listdata[i].no <= 35) {
              five.push(this.listdata[i]);
            } else if (this.listdata[i].no > 35 && this.listdata[i].no <= 42) {
              six.push(this.listdata[i]);
            }
          }

          // console.log(one);
          // console.log(two);
          // console.log(three);
          // console.log(four);
          // console.log(five);
          for (let l = 0; l < this.list.length; l++) {
            // console.log(this.list[l]);
            if (this.list[l].id == 1) {
              this.list[l].sub = one;
            } else if (this.list[l].id == 2) {
              this.list[l].sub = two;
            } else if (this.list[l].id == 3) {
              this.list[l].sub = three;
            } else if (this.list[l].id == 4) {
              this.list[l].sub = four;
            } else if (this.list[l].id == 5) {
              this.list[l].sub = five;
            } else if (this.list[l].id == 6) {
              this.list[l].sub = six;
            }
          }
          // console.log(this.list);
          const datenow = new Date();
          // console.log(datenow.getMonth());
          for (let ll = 0; ll < this.list.length; ll++) {
            for (let s = 0; s < this.list[ll].sub.length; s++) {
             
              this.list[ll].sub[s].class = null
              if (
                this.list[ll].sub[s].date == datenow.getDate() &&
                this.list[ll].sub[s].month == datenow.getMonth()+1 &&
                this.list[ll].sub[s].year == datenow.getFullYear()
              ) {
                  this.list[ll].sub[s].class = "#bb9ad9";
              }
            }
          }
        }
      );
    },
    insertdata() {
      const d = new Date(
        this.$route.params.year,
        this.$route.params.month - 1,
        1
      );
      var firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
      var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      var startday = firstDay.getDay();
      var lastday = lastDay.getDate();
      // console.log(lastDay);

      // console.log(startday,'startday');

      // console.log(lastday,'lastday');
      // console.log(lastDay.getDate());
      // console.log(Math.ceil(lastday));
      var allday = [];
      for (let l = 0; l < lastDay.getDate(); l++) {
        // var listday = new Date(d.getFullYear(), d.getMonth() , l+1);
        // var num = listday.getDay()
        allday.push({
          id: l + 1,
          date: l + 1,
          month: this.month,
          year: this.year,
          // week:num,
          class: "",
        });
      }
      // console.log(allday,'allday');

      // console.log(startday,'startday');
      if (startday != 0) {
        var countadd = startday;
        for (let c = 0; c < countadd; c++) {
          allday.unshift({
            no: 0,
            date: "",
            month: this.month,
            year: this.year,
            // week:countadd-c,
            class: "",
          });
        }
      }

      // console.log(allday,'allday');
      // console.log(lastday);
      var splitweek = Math.ceil(lastday / 7);
      // console.log(splitweek);
      // if (typeof (lastday/7) === 'number') {
      //   splitweek = splitweek+1
      // }
      // console.log(splitweek);
      var allmonth = 7 * splitweek - allday.length;
      if (7 * splitweek < allday.length) {
        allmonth = 7 * (splitweek + 1) - allday.length;
      }
      // console.log(allmonth);
      for (let am = 0; am < allmonth; am++) {
        allday.push({
          no: 0,
          date: "",
          month: this.month,
          year: this.year,
          // week:'',
          class: "",
        });
      }

      let id = 1;
      allday.forEach((object) => {
        object.no = id;
        id += 1;
      });
      // console.log(allday);
      for (let a = 0; a < allday.length; a++) {
        var data = {
          no: allday[a].no,
          date: allday[a].date,
          month: allday[a].month,
          year: allday[a].year,
          moodId: 0,
          userId: 1,
        };
        MoodTrackingService.createmood_tracking(data).then(() => {
          // console.log(res.data);
          if (allday.length == a + 1) {
            this.getdata();
          }
        });
      }
    },
    add(s) {
      // console.log(s);

      if (s.date != "") {
        var m = (parseInt(s.month))-1
        var dn = new Date(s.year, m, s.date);
        const options = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        this.datename = dn.toLocaleString("en-ZA", options);

        document.getElementById("openmodel").click();
        MoodTrackingService.getmood_tracking(s.id).then((res) => {
          this.data = res.data;
        });
      }
    },
    gotopage(data) {
      // console.log(data);
      var date = new Date();
      // console.log(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var full =
        year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
      // console.log(full);

      CountService.getcounts(full, data.id, "", "", "", "").then((res) => {
        // console.log(res.data);
        // let urlcheck = data.url.includes("https://")
        // var url = data.url
        // console.log(url);
        // if (!urlcheck) {
        //       url = 'https://'+data.url
        //     }
        //     console.log(url);
        if (res.data[0].id == null) {
          var countsdata = {
            urlId: data.id,
            no: 1,
            date: full,
          };
          // console.log(countsdata);
          CountService.createcount(countsdata).then(() => {
            // console.log(res.data);
            // window.open(url, '_system');
          });
        } else {
          var updatecountsdata = {
            no: res.data[0].no + 1,
          };
          CountService.updatecount(res.data[0].id, updatecountsdata).then(
            () => {
              // console.log(res.data);
              // window.open(url, '_system');
            }
          );
        }
      });
    },
    getURL() {
      UrlService.geturls().then((res) => {
        this.list = res.data;
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background-color: #000;
}

.calendar {
  width: 100%;
  background-color: #fff;
}

.calendar-head {
  display: flex;
  min-height: 30px;
  background-color: #eee;
  color: #fff;
}

.dayname {
  font-size: 0.8rem;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-transform: uppercase;
  color: black;
  overflow: hidden;
  background-color: #3db176;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  height: 30px;
}

.row {
  display: flex;
  /* min-height: 150px; */
}

.day {
  min-height: 80px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  color: #000;
  padding: 5px;
  box-shadow: -1px -1px #d5d7db, inset -1px -1px 0 0 #d5d7db;
}

.day.next {
  color: #d5d7db;
}
.square {
  height: 50px;
  width: 50px;
  background-color: #555;
}
</style>
