import http from "../http-common";

class MoodService {
  createmood(data) {
    return http.post('/mood', data);
  }
  getmoods() {
    return http.get(`/mood`);
  }
  getusers() {
    return http.get(`/users`);
  }
  getmood(id) {
    return http.get(`/mood/` + id);
  }
  updatemood(id, data) {
    return http.put(`/mood/${id}`, data);
  }
  updateno(id, data) {
    return http.put(`/mood/updateno/${id}`, data);
  }
  deletemood(id) {
    return http.delete(`/mood/` + id);
  }
}

export default new MoodService();